@import '../../../index';

.listBlock {
  .timelineProcess {
    margin: 85px auto 0;
    display: flex;
    justify-content: center;
    .timlineBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      .timelineCircle {
        width: 75px;
        height: 75px;
        background: #e9f8f4;
        border-radius: 50%;
        border: 1px solid #b3e8d8;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto;
        &:before {
          content: '';
          background: transparent;
          width: 100px;
          height: 120px;
          position: absolute;
          top: -80px;
          left: 30px;
          opacity: 0;
        }
        .iconWrap {
          position: absolute;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          left: 3px;
          top: 77px;
          z-index: -1;
          .iconBox {
            width: 32px;
            height: 32px;
            background: #ffecef;
            border: 1px solid #f43b56;
            border-radius: 50%;
            text-align: center;
            line-height: 32px;
            &:first-child {
              position: absolute;
              left: 4px;
              top: -74px;
            }
            &:last-child {
              position: absolute;
              left: 40px;
              top: -46px;
            }
            &.edit {
              svg {
                width: 14px;
                height: 14px;
                path {
                  stroke: #f43b56;
                }
              }
            }
          }
        }
        &:hover {
          .iconWrap {
            opacity: 1;
            left: 40px;
            top: 37px;
            // z-index: 1;
          }
          .timelineCircle {
            &:before {
              opacity: 1;
            }
          }
        }
      }
      .timelineText {
        position: absolute;
        bottom: -60px;
        left: -35px;
        width: 165px;
        text-align: center;
      }
      .timelineBorder {
        width: 130px;
        height: 6px;
        background: #e9f8f4;
        border-top: 1px solid #b3e8d8;
        border-bottom: 1px solid #b3e8d8;
        position: relative;
        @media (max-width: 1400px) {
          width: 100px;
        }
        .addCircle {
          display: inline-block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          background: #e9f8f4;
          border-radius: 50%;
          border: 1px solid #b3e8d8;
          cursor: pointer;
          padding: 0;
          margin: 0;
          color: $primary-color;
          svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
          }
          span.p-button-icon {
            line-height: 27px;
            margin: 0 auto;
          }
          .p-button-label {
            font-size: 0;
          }
        }
      }
      &:last-child {
        .timelineBorder {
          display: none;
        }
      }
    }
  }
  .notificationText {
    font-size: 18px;
    line-height: 25px;
    color: $grey-color;
    margin: 150px 0 30px;
  }
  .btnsWrap {
    margin-top: auto;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin-top: 30px;
    }
    .p-button {
      width: 280px;
      margin-top: 0;
      @media (min-width: 768px) and (max-width: 991px) {
        width: 200px;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-top: 10px;
      }
      &.p-button-noBg {
        margin-left: 10px;
        margin-right: 10px;
        @media (max-width: 767px) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  .p-dialog-enter-done {
    max-width: 500px !important;
    width: 500px !important;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 36px;
    line-height: 43px;
    font-weight: 400;
  }
  .inputBox {
    margin-bottom: 30px;
  }
  .textareaBox .p-inputtextarea {
    width: 100% !important;
  }
  .p-dialog {
    .p-dialog-footer {
      button {
        width: 190px;
        .p-button-label {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    label,
    .textareaBox label {
      font-size: 16px;
      line-height: 22px;
      color: $dark-grey-color;
    }
  }
  .timelineTask {
    padding-top: 30px;
    .timelineList {
      display: flex;
      // background: #fff;
      border-radius: 10px;
      border-bottom: 2px solid #deddde;
      overflow: hidden;
      &:nth-child(2) {
        border-top-left-radius: 0;
      }
      &.headingText {
        background: transparent;
        border-bottom: none;
        overflow: visible;
        .timelineField {
          padding: 0;
          background: transparent;
        }
      }
      .timelineField {
        background: #fff;
        &:first-child {
          width: 40%;
          @media (max-width: 1199px) {
            width: 35%;
          }
        }
        &:last-child {
          justify-content: flex-end;
          width: 120px;
        }
        width: 20%;
        padding: 12px 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: 1199px) {
          &:nth-child(2) {
            width: 35%;
          }
          &:nth-child(3),
          &:nth-child(4) {
            width: 20%;
          }
        }
        &.inputWrap {
          display: flex;
        }
        .dropdownBox {
          label {
            margin-bottom: 0;
          }
          .p-dropdown {
            height: 40px;
            width: 200px;
            text-align: left;
            .p-dropdown-label {
              height: 40px;
              line-height: 20px;
            }
          }
        }
        .p-button {
          margin-top: 0;
          background: #6983e2;
          border-color: #6983e2;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          .p-button-label {
            font-size: 16px;
            line-height: 22px;
          }
        }
        .stageBtn {
          border-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border: none;
          font-size: 12px;
          line-height: 16px;
          color: #fff;
          padding: 10px 20px;
          cursor: pointer;
          position: relative;
          text-transform: uppercase;
          width: 100%;
        }
        .titleText {
          color: #737b88;
          font-size: 14px;
          line-height: 20px;
          text-transform: uppercase;
          padding: 8px 10px;
          cursor: pointer;
          height: 30px;
          margin-bottom: 4px;
          svg {
            margin-left: 10px;
            vertical-align: middle;
            cursor: pointer;
          }
          &:hover {
            background: #eeedee;
            border-radius: 4px;
          }
        }
        .taskTitle {
          color: #868c94;
          font-size: 16px;
          line-height: 22px;
          padding: 0 0 0 10px;
          border: none;
          background: transparent;
          outline: none;
          // width: 100%;
        }
        .field-checkbox {
          margin-bottom: 0;
          width: 22px;
        }
        .assignee {
          .assigneeImg {
            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              vertical-align: middle;
            }
          }
          .assigneeName {
            padding-left: 15px;
            vertical-align: middle;
            font-size: 16px;
            line-height: 22px;
            color: #868c94;
            width: calc(100% - 24px);
            display: inline-block;
          }
        }
        .dueDate {
          color: #fb6b25;
        }
        .wrapBtns {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          // svg {
          //   width: 23px;
          //   height: 23px;
          //   path {
          //     stroke: $grey-color;
          //   }
          // }
          .editBtn,
          .checkBtn {
            cursor: pointer;
            height: 23px;
          }
          .deleteBtn {
            margin-left: 30px;
            height: 23px;
            cursor: pointer;
            svg {
              path {
                stroke: #f43b56;
              }
            }
          }
          .divider {
            width: 1px;
            background: #fff;
            height: 20px;
            margin: 0 8px;
          }
        }
        .timelineBtn {
          position: relative;
          display: inline-block;
          // width: 100px;
          &:hover {
            .p-button {
              padding-right: 50px;
              position: relative;
            }
            .stageBtn {
              padding-right: 50px;
              position: relative;
            }
            .wrapBtns {
              opacity: 1;
              .deleteBtn {
                margin-left: 10px;
                height: 15px;
              }
              .editBtn,
              .checkBtn {
                height: 20px;
              }
            }
          }
          .wrapBtns {
            position: absolute;
            right: 15px;
            bottom: 8px;
            opacity: 0;

            height: auto;
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
        .p-checkbox {
          width: 22px !important;
          height: 22px !important;
        }
      }
      .p-checkbox .p-checkbox-box {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 2px solid #0050fc;
        background: #fff;
        // &:before {
        //   content: "";
        //   background: linear-gradient(221.31deg, #01D0F2 4.91%, #0049FD 101.19%);
        //   width: 24px;
        //   height: 24px;
        //   display: inline-block;
        // }
        &.p-highlight {
          background: linear-gradient(221.31deg, #01d0f2 4.91%, #0049fd 101.19%);
          border: none;
        }
        .p-checkbox-icon {
          color: #2b2b2b;
          font-size: 20px;
          &:before {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.18039 0.267154C9.58513 0.642923 9.60862 1.27565 9.23285 1.68039L4.23422 7.06444C4.05223 7.26047 3.79928 7.37541 3.53192 7.38358C3.26456 7.39176 3.00507 7.29247 2.81144 7.10793L0.310075 4.72388C-0.0897124 4.34285 -0.104913 3.70987 0.276123 3.31008C0.657159 2.91029 1.29014 2.89509 1.68993 3.27613L3.45788 4.96115L7.76715 0.319615C8.14292 -0.0851276 8.77565 -0.108615 9.18039 0.267154Z' fill='white'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            width: 10px;
            height: 8px;
            position: absolute;
            left: 7px;
            top: 7px;
          }
        }
      }
      &:hover {
        &:not(.headingText) {
          // background: #FAFBFC;
          background: #4f97ec;
          .taskTitle {
            color: #4f97ec;
          }
          .timelineField {
            background: #fafbfc;
            &:last-child {
              background: #4f97ec;
              .wrapBtns svg {
                path {
                  stroke: #fff;
                }
              }
            }
            &:nth-child(4) {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
    .iconSet {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .addUser {
        font-size: 12px;
        line-height: 26px;
        color: #737b88;
        cursor: pointer;
        margin-top: 5px;
        display: inline-block;
        margin-left: 30px;
        background: transparent;
        border-radius: 4px;
        padding: 2px 10px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: #eeedee;
        }
      }
      .addNew {
        color: #369075;
        display: flex;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 5px;
        cursor: pointer;
        .iconBox {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #369075;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
        }
        &:hover {
          color: $primary-hover-color;
        }
      }
    }
  }
  .completeBtn {
    display: flex;
    justify-content: flex-end;
    .p-button.p-button-secondary {
      background: #121212;
      border-color: #121212;
      border-radius: 12px;
      color: #fff;
      padding: 10px 50px;
      height: 70px;
      .p-button-label {
        font-size: 22px;
        line-height: 30px;
      }
      &:enabled:hover {
        background: #181818;
        border-color: #181818;
        color: #fff;
      }
    }
  }
}
