@import '../../index';
.mainBanner {
  padding: 47px 70px;
  background: linear-gradient(to left, #0050fc, #01d0f2);
  @media (max-width: 1199px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 991px) {
    padding: 40px 40px;
  }
  h1 {
    margin: 0;
    font-size: 44px;
    line-height: 62px;
    color: #fff;
    font-weight: 600;
    @media (max-width: 991px) {
      font-size: 32px;
      line-height: 36px;
    }
  }
}
