@import '../../index';
.companyProgressInfo {
  background: linear-gradient(221.31deg, #01d0f2 4.91%, #0049fd 101.19%);
  padding: 45px;
  border-radius: 6px;
  max-width: 455px;
  min-height: calc(100vh - 2rem);
  display: flex;

  // @media (max-width: 1025px) {
  //   padding: 10px;
  // }
  @media (max-width: 991px) {
    padding: 30px;
  }
  @media (max-width: 767px) {
    margin: 0 auto;
    padding: 20px;
    min-height: auto;
    max-width: 700px;
  }
  .boxProgressWrap {
    display: flex;
    flex-direction: column;
    .stepsBox {
      margin-top: auto;
      .steps {
        display: flex;
        align-items: flex-start;
        .status {
          width: 18px;
          height: 18px;
          background: transparent;
          border: 1px solid #fff;
          border-radius: 50%;
          span {
            height: 45px;
            display: inline-block;
            background: $white-color;
            width: 1px;
            position: relative;
            left: 7px;
            top: 22px;
          }
        }
        &.complete {
          .status {
            background: #fff;
            position: relative;
            &:before {
              content: '\e909';
              font-family: primeicons;
              color: #4f97ec;
              font-size: 11px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 700;
            }
          }
        }
        .titleWrap {
          margin-bottom: 25px;
          padding-left: 15px;

          .progressTitle {
            margin-top: auto;
            position: relative;

            // ::before {
            //   content: '';
            //   position: absolute;
            //   width: 18.53px;
            //   height: 18.53px;
            //   background: #f9f5ff;
            //   border: 0.926547px solid #369075;
            //   border-radius: 9.26547px;
            //   top: 4px;
            //   left: -33px;
            // }
            // ::after {
            //   content: '';
            //   position: absolute;
            //   height: 60px;
            //   border: 0.926547px solid #ffffff;
            //   top: 30px;
            //   left: -25px;
            // }
            .progressTitleHead {
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: $white-color;
              margin: 0 !important;
            }
          }
          .progresstext {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $white-color;
            margin: 5px 0;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
  }
  .logo-div {
    margin-bottom: 35px;
  }
}
