@import '../../index';
.mainHeader {
  background: $dark-black-color;
  padding: 22px 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1199px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 991px) {
    padding: 10px 40px;
  }
  .logo {
    svg {
      @media (max-width: 991px) {
        width: 120px;
      }
    }
  }

  .searchBox {
    position: relative;
    width: 700px;
    margin: 0 auto;
    @media (max-width: 1199px) {
      width: 500px;
    }
    @media (max-width: 991px) {
      width: 400px;
    }
    .inputBox {
      label {
        display: none;
      }
      input {
        background: #1c1f25;
        border: none;
        border-radius: 10px;
        height: 52px;
        padding: 10px 60px 10px 20px;
        color: #868c94;
        @media (max-width: 991px) {
          height: 45px;
        }
      }
      input::-webkit-input-placeholder {
        color: #868c94;
      }
      input::-moz-placeholder {
        color: #868c94;
      }
      input:-ms-input-placeholder {
        color: #868c94;
      }
      input:-moz-placeholder {
        color: #868c94;
      }
    }
    .searchIcon {
      position: absolute;
      top: 12px;
      right: 20px;
      cursor: pointer;
      @media (max-width: 991px) {
        top: 9px;
      }
      @media (max-width: 991px) {
        svg {
          width: 20px;
        }
      }
    }
  }
  .profileDropdown {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #0050fc, #01d0f2);
    display: flex;
    justify-content: center;
    align-items: center;
    .p-avatar {
      font-size: 0.9rem;
      width: 40px;
      height: 40px;
      color: $light-grey-color;
      cursor: pointer;
      background: #23272e;
      img {
        border-radius: 50%;
      }
    }
  }
}
