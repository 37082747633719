@import '../../../index';
.propertyDetailView {
  padding-left: 40px;
  width: 40%;
  @media (max-width: 1199px) {
    padding-left: 20px;
    width: 35%;
  }
  .title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    color: #1c1f25;
  }
  .location {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #868c94;
    padding-top: 10px;
  }
  .list {
    padding: 0;
    margin: 20px 0 0;
    display: flex;
    li {
      list-style: none;
      text-align: left;
      padding-right: 25px;
      color: #1c1f25;
      font-size: 18px;
      line-height: 25px;
      &:last-child {
        padding-right: 0;
      }
      span {
        display: block;
        font-size: 16px;
        line-height: 22px;
        color: #868c94;
      }
    }
  }
}
