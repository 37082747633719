@import '../../index.scss';
.smartyAddress {
  .formGroup {
    position: relative;
    max-width: 800px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  label {
    margin-bottom: 6px;
  }
  .p-button {
    width: 100%;
    margin-top: 40px;
    max-width: 560px;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .list {
    background: #fff;
    border: 1px solid #369075;
    border-radius: 0 0 6px 6px;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    max-width: 523px;
    overflow-y: auto;
    padding: 0 !important;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 11;
    width: 480px;
  }
  .listData {
    border: 1px solid transparent;
    cursor: pointer;
    padding: 4px 0 4px 25px !important;
    text-align: left;
    color: #7d7d7d;
  }
  .locationIcon {
    left: 10px;
    top: 68px;
    position: absolute;
    transform: translateY(-80%);
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .mapMarker {
    position: absolute;
    transform: translate(-23px, 0px);
  }
  .p-inputtext {
    padding: 0px 40px !important;
  }
  .downArrow {
    background: url(../../assets/svg/caret-down.svg) no-repeat 0 center;
    width: 12px;
    height: 30px;
    display: inline-block;
    margin-left: 3px;
    border: none;
    transform: rotate(180deg);
    position: absolute;
    margin-top: -3px;
  }
}
