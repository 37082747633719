.propertyBlock {
  padding: 40px;
  background: #eeedee;
  border-radius: 6px;
  .title {
    font-size: 22px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 26px;
  }
  label {
    font-size: 16px;
    line-height: 23px;
    color: #23272e;
  }
  .divider {
    border-bottom: 1px dashed #d2cfcf;
    margin: 26px -40px;
  }
  .dropdownBox {
    max-width: 800px;
    .p-dropdown {
      width: 100%;
    }
  }
  .wrapBtn {
    margin-top: 26px;
    .p-button {
      width: 210px;
      span {
        font-size: 16px;
        line-height: 22px;
      }
      &:last-child {
        margin-left: 26px;
      }
    }
  }
}
