@import '../../index';
.filterSearch {
  padding: 0 70px;
  @media (max-width: 1199px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 991px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  .btnsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .p-button {
      width: 50%;
      margin-top: 0;
      background: #eeedee;
      border-color: #eeedee;
      text-align: left;
      border-right: 1px solid #deddde;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      display: flex;
      span {
        font-size: 16px;
        line-height: 22px;
        color: #1c1f25;
        font-weight: 500;
        .pi-open:before {
          content: 'dfd';
          background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.75 10.4626V2.91881C13.75 2.16881 13.1375 1.61256 12.3937 1.67506H12.3563C11.0438 1.78756 9.05 2.45631 7.9375 3.15631L7.83125 3.22506C7.65 3.33756 7.35 3.33756 7.16875 3.22506L7.0125 3.13131C5.9 2.43756 3.9125 1.77506 2.6 1.66881C1.85625 1.60631 1.25 2.16881 1.25 2.91256V10.4626C1.25 11.0626 1.7375 11.6251 2.3375 11.7001L2.51875 11.7251C3.875 11.9063 5.96875 12.5938 7.16875 13.2501L7.19375 13.2626C7.3625 13.3563 7.63125 13.3563 7.79375 13.2626C8.99375 12.6001 11.0938 11.9063 12.4563 11.7251L12.6625 11.7001C13.2625 11.6251 13.75 11.0626 13.75 10.4626Z' stroke='%231C1F25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 3.42969V12.8047V3.42969Z' fill='%231C1F25'/%3E%3Cpath d='M7.5 3.42969V12.8047' stroke='%231C1F25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.84375 5.30469H3.4375' stroke='%231C1F25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.3125 7.17969H3.4375' stroke='%231C1F25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          width: 15px;
          height: 15px;
          display: inline-block;
        }
      }
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-right: none;
      }
      svg {
        margin-right: 10px;
        order: -1;
      }
      &:enabled:active {
        background: #23272e;
        span {
          color: #fff;
        }
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
  .searchBx {
    label {
      display: none;
    }
    input {
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: 52px;
      padding-left: 20px;
      padding-right: 20px;
    }
    input::-webkit-input-placeholder {
      color: #1c1f25;
    }
    input::-moz-placeholder {
      color: #1c1f25;
    }
    input:-ms-input-placeholder {
      color: #1c1f25;
    }
    input:-moz-placeholder {
      color: #1c1f25;
    }
  }
  .active {
    background-color: #23272e !important;
    span {
      color: #fff !important;
    }
    svg {
      path {
        stroke: #fff !important;
      }
    }
  }
}
