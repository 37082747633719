@import '../../index';
.timlineHeader {
  background: linear-gradient(180deg, #0050fc 0%, #01d0f2 100%);
  padding: 38px 40px;
  // margin-top: -1rem;
  // margin-left: -1rem;
  // margin-right: -1rem;
  .logo {
    display: inline-block;
    cursor: pointer;
  }
}
