@import '../../index';
.mainCard {
  padding: 0 70px;
  margin-top: 40px;
  @media (max-width: 1199px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 991px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  .cardWrap {
    margin-left: -1.563rem;
    margin-right: -1.563rem;
    .cardBox {
      padding-left: 1.563rem;
      padding-right: 1.563rem;
      .p-card.p-component {
        background: transparent;
        padding: 0;
        margin-bottom: 50px;
        .cardImg {
          position: relative;
          img {
            width: 100%;
            height: 260px;
            object-fit: cover;
            border-radius: 14px;
          }
          .cardCaption {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 6px;
            background: #1c1f25;
            padding: 9px 15px;
            color: #fff;
            font-size: 16px;
            line-height: 22px;
            border-radius: 0 0 13px 13px;
          }
        }
        .priceWrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 20px;
          .price {
            font-size: 26px;
            line-height: 36px;
            color: #23272e;
            font-weight: 500;
            @media (max-width: 1199px) {
              font-size: 24px;
              line-height: 34px;
            }
          }
          .userGroup {
            .p-avatar.p-avatar-lg {
              width: 30px;
              height: 30px;
              font-size: 12px;
            }
            .p-avatar-group .p-avatar {
              background: #4f97ec;
              color: #fff;
              font-size: 12px;
              font-weight: 600;
            }
            .p-avatar-group .p-avatar + .p-avatar {
              margin-left: -0.8rem;
              border: 2px solid #ffffff;
            }
          }
        }
        .address {
          font-size: 22px;
          line-height: 30px;
          color: #23272e;
          padding-top: 10px;
          @media (max-width: 1199px) {
            font-size: 20px;
            line-height: 28px;
          }
          span {
            display: block;
            color: #868c94;
            font-size: 19px;
            line-height: 26px;
            padding-top: 3px;
            @media (max-width: 1199px) {
              font-size: 17px;
              line-height: 24px;
            }
          }
        }
        .viewAll {
          margin-top: 20px;
          .p-button {
            border-radius: 30px;
            background: #23272e;
            border: #23272e;
            height: 46px;
            margin-top: 0;
            padding-left: 30px;
            padding-right: 30px;
            font-size: 18px;
            font-weight: 500;
            span {
              font-size: 17px;
              line-height: 24px;
            }
            &:enabled:hover {
              background: #1c1f25;
              border-color: #1c1f25;
            }
          }
        }
      }
    }
  }
}
