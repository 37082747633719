@import '../../index';
.addPropertyInfo {
  .propertyInfoBox {
    max-width: 800px;
    margin: 0;
    min-height: auto;
    display: block;
    @media (max-width: 776px) {
      margin-top: 15px;
    }
    h1 {
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: $dark-grey-color;
      margin: 0 0 26px;
    }
    .propertyInfoMain {
      margin-top: 0;
      @media (max-width: 776px) {
        margin-top: 10px;
      }
    }
    .numberinput {
      width: 100%;
    }
    .p-button.p-fileupload-choose {
      width: 143px;
      height: 139px;
      background: #fff;
      border-radius: 6px;
      color: $grey-color;
      border: none;
      margin-top: 0px;
      font-size: 16px;
      line-height: 22px;
      border: 1px dashed #868c94;
      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        background: #fff;
        border: 1px dashed #868c94;
      }
      .p-button-label {
        font-size: 16px;
        line-height: 22px;
        color: $grey-color;
        font-weight: 500;
        &::before {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19ZM3 19L14 8L19 13M8 6.5C8 7.32843 7.32843 8 6.5 8C5.67157 8 5 7.32843 5 6.5C5 5.67157 5.67157 5 6.5 5C7.32843 5 8 5.67157 8 6.5Z' stroke='%23868C94' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          width: 20px;
          height: 20px;
          display: block;
          margin: 0 auto 10px;
        }
      }
    }
    .p-button .p-button-icon-left {
      margin-right: 0.5rem;
      display: none;
    }
    .propertyUpload {
      position: relative;
      @media (max-width: 1199px) {
        width: 100%;
        margin-bottom: 15px;
      }
      .imgWrap {
        position: relative;
        display: inline-block;
        .delUser {
          position: absolute;
          top: -13px;
          right: -13px;
          background: $primary-color;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
      img.uploadImg {
        border: 0.705645px dashed #0f0f0f;
        border-radius: 6px;
        padding: 0;
      }
    }

    .Propertyform {
      @media (max-width: 1199px) {
        width: 100%;
      }
    }

    .p-inputgroup {
      > div {
        width: 100%;
      }
      label {
        margin-bottom: 0;
      }
      .p-inputgroup-addon {
        background: none;
        border-color: $grey-color;
      }
      input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        width: 100%;
      }
    }
    .inputBox {
      margin-bottom: 26px;
    }
    .textareaBox {
      margin-bottom: 0;
      textarea {
        width: 100%;
        min-height: 100px;
        overflow-x: auto;
        resize: none;
      }
    }
  }
}
