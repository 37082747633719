@import '../../index';
.taskBlock {
  .wrapper {
    max-width: 1360px;
    margin: 0 auto;
  }
  .titleWrap {
    margin-top: 60px;
    margin-bottom: 40px;
    h1 {
      font-size: 45px;
      line-height: 54px;
      font-weight: 600;
      color: $dark-grey-color;
      margin-top: 0;
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
      line-height: 25px;
      color: $grey-color;
      margin: 0;
    }
    // .timelineName {
    //     max-width: 430px;
    //     margin-left: auto;
    //     position: relative;
    //     .editBtn {
    //         position: absolute;
    //         right: 15px;
    //         bottom: 15px;
    //         border-left: 1px solid $grey-color;
    //         svg {
    //             margin-left: 20px;
    //             vertical-align: middle;
    //             cursor: pointer;
    //         }
    //     }
    //     input {
    //         padding-right: 70px;
    //     }
    // }
    input {
      max-width: 470px;
    }
    .notificationBox {
      background: #fff;
      border-radius: 20px;
      padding: 20px;
      display: flex;
      align-items: center;
      max-width: 445px;
      margin-left: auto;
      .iconBox {
        margin-right: 15px;
        .pi {
          color: #737b88;
          font-size: 40px;
        }
      }
      .text {
        color: #737b88;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .timelineProcess {
    margin: 85px auto 0;
    display: flex;
    justify-content: center;
    .timlineBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      .timelineCircle {
        width: 75px;
        height: 75px;
        background: #e9f8f4;
        border-radius: 50%;
        border: 1px solid #b3e8d8;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto;
        &:before {
          content: '';
          background: transparent;
          width: 100px;
          height: 120px;
          position: absolute;
          top: -80px;
          left: 30px;
          opacity: 0;
        }
        .iconWrap {
          position: absolute;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          left: 3px;
          top: 77px;
          z-index: -1;
          .iconBox {
            width: 32px;
            height: 32px;
            background: #ffecef;
            border: 1px solid #f43b56;
            border-radius: 50%;
            text-align: center;
            line-height: 32px;
            &:first-child {
              position: absolute;
              left: 4px;
              top: -74px;
            }
            &:last-child {
              position: absolute;
              left: 40px;
              top: -46px;
            }
            &.edit {
              svg {
                width: 14px;
                height: 14px;
                path {
                  stroke: #f43b56;
                }
              }
            }
          }
        }
        &:hover {
          .iconWrap {
            opacity: 1;
            left: 40px;
            top: 37px;
            // z-index: 1;
          }
          .timelineCircle {
            &:before {
              opacity: 1;
            }
          }
        }
      }
      .timelineText {
        position: absolute;
        bottom: -60px;
        left: -35px;
        width: 165px;
        text-align: center;
      }
      .timelineBorder {
        width: 130px;
        height: 6px;
        background: #e9f8f4;
        border-top: 1px solid #b3e8d8;
        border-bottom: 1px solid #b3e8d8;
        position: relative;
        @media (max-width: 1400px) {
          width: 100px;
        }
        .addCircle {
          display: inline-block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          background: #e9f8f4;
          border-radius: 50%;
          border: 1px solid #b3e8d8;
          cursor: pointer;
          padding: 0;
          margin: 0;
          color: $primary-color;
          svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
          }
          span.p-button-icon {
            line-height: 27px;
            margin: 0 auto;
          }
          .p-button-label {
            font-size: 0;
          }
        }
      }
      &:last-child {
        .timelineBorder {
          display: none;
        }
      }
    }
  }
  .notificationText {
    font-size: 18px;
    line-height: 25px;
    color: $grey-color;
    margin: 150px 0 30px;
  }
  .btnsWrap {
    margin-top: auto;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin-top: 30px;
    }
    .p-button {
      width: 280px;
      margin-top: 0;
      @media (min-width: 768px) and (max-width: 991px) {
        width: 200px;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-top: 10px;
      }
      &.p-button-noBg {
        margin-left: 10px;
        margin-right: 10px;
        @media (max-width: 767px) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  .p-dialog-enter-done {
    max-width: 500px !important;
    width: 500px !important;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 36px;
    line-height: 43px;
    font-weight: 400;
  }
  .inputBox {
    margin-bottom: 30px;
    label {
      font-size: 19px;
      line-height: 23px;
      margin-bottom: 16px;
    }
  }
  .textareaBox .p-inputtextarea {
    width: 100% !important;
  }
  .p-dialog {
    .p-dialog-footer {
      button {
        width: 190px;
        .p-button-label {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    label,
    .textareaBox label {
      font-size: 16px;
      line-height: 22px;
      color: $dark-grey-color;
    }
  }
  .timelineTask {
    margin-bottom: 45px;
    .timelineList {
      display: flex;
      background: #fff;
      border-radius: 6px;
      margin-bottom: 2px;
      &:first-child {
        margin-bottom: 0;
      }
      &:nth-child(2) {
        border-top-left-radius: 0;
      }
      &.headingText {
        background: transparent;
        border-bottom: none;
        .timelineField {
          padding: 0 15px;
        }
      }
      .timelineField {
        &:first-child {
          width: 40%;
          text-align: left;
          padding: 0;
          @media (max-width: 991px) {
            width: 30%;
          }
        }
        width: 20%;
        padding: 7px 15px;
        text-align: left;
        @media (max-width: 991px) {
          width: 25%;
        }
        .dropdownBox {
          label {
            margin-bottom: 0;
          }
          .p-dropdown {
            height: 40px;
            line-height: 40px;
            width: 200px;
            border: none;
            text-align: left;
            padding: 0 10px;
            .p-dropdown-label {
              height: 40px;
              line-height: 40px;
              padding: 0;
              color: $grey-color;
            }
            @media (max-width: 991px) {
              width: 150px;
            }
          }
        }
        .p-button {
          margin-top: 0;
          background: #6983e2;
          border-color: #6983e2;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          .p-button-label {
            font-size: 16px;
            line-height: 22px;
          }
        }
        .stageBtn {
          border-radius: 6px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border: none;
          font-size: 12px;
          line-height: 16px;
          color: #fff;
          padding: 10px 15px;
          cursor: pointer;
          position: relative;
          text-transform: uppercase;
          font-weight: 400;
        }
        .titleText {
          color: $grey-color;
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
          padding: 5px 10px;
          cursor: pointer;
          display: inline-block;
          svg {
            vertical-align: middle;
            margin-left: 10px;
          }
          &:hover {
            background: #eeedee;
            border-radius: 6px;
          }
          @media (max-width: 991px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .taskTitle {
          color: $grey-color;
          font-size: 16px;
          line-height: 22px;
          padding: 17px 15px;
          border: none;
          background: transparent;
          outline: none;
          width: 100%;
          @media (max-width: 991px) {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .field-checkbox {
          justify-content: flex-start;
          margin-bottom: 0;
        }
        .wrapBtns {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          svg {
            // width: 23px;
            // height: 23px;
            path {
              stroke: $grey-color;
            }
          }
          .editBtn,
          .deleteBtn {
            cursor: pointer;
            height: 20px;
          }
          .deleteBtn {
            margin-left: 30px;
            svg {
              path {
                stroke: #f43b56;
              }
            }
          }
          .divider {
            width: 1px;
            background: #fff;
            height: 20px;
            margin: 0 8px;
          }
        }
        .timelineBtn {
          position: relative;
          display: inline-block;
          &:hover {
            .p-button {
              padding-right: 80px;
              position: relative;
            }
            .stageBtn {
              padding-right: 80px;
              position: relative;
            }
            .wrapBtns {
              opacity: 1;
              .deleteBtn {
                margin-left: 0;
              }
            }
          }
          .wrapBtns {
            position: absolute;
            right: 15px;
            bottom: 8px;
            opacity: 0;

            height: auto;
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
        .p-checkbox {
          width: 35px !important;
          height: 35px !important;
        }
      }
      .p-checkbox .p-checkbox-box {
        width: 35px;
        height: 35px;
        border-radius: 6px;
        border: 2px solid #2b2b2b;
        &.p-highlight {
          background: transparent;
        }
        .p-checkbox-icon {
          color: #2b2b2b;
          font-size: 20px;
        }
      }
      &:hover:not(.headingText) {
        .timelineField {
          &:last-child {
            background: $secondary-color;
            position: relative;
            &:before {
              content: '';
              background: #fff;
              width: calc(100% - 120px);
              height: 56px;
              position: absolute;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
              top: 0;
              left: 0;
            }
            .wrapBtns {
              svg {
                path {
                  stroke: #fff;
                }
              }
            }
          }
        }
      }
    }
    .iconSet {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .addUser {
        font-size: 12px;
        line-height: 16px;
        color: $grey-color;
        cursor: pointer;
        margin-top: 3px;
        padding: 7px 10px;
        display: inline-block;
        &:hover {
          background: $light-grey-color;
          border-radius: 6px;
        }
      }
      .addNew {
        color: $secondary-color;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
        padding: 7px 10px;
        &:hover {
          background: $light-grey-color;
          border-radius: 6px;
        }
      }
    }
  }
  .completeBtn {
    display: flex;
    justify-content: flex-end;
    .p-button.p-button-secondary {
      background: #121212;
      border-color: #121212;
      border-radius: 12px;
      color: #fff;
      padding: 10px 20px;
      height: 54px;
      .p-button-label {
        font-size: 18px;
        line-height: 21px;
      }
      &:enabled:hover {
        background: #181818;
        border-color: #181818;
        color: #fff;
      }
    }
  }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: $grey-color;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: $light-grey-color;
  color: $grey-color;
}
