@import '../../index';
.propertyBox {
  max-width: 500px;
  margin: 0 auto;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    margin: 0 0 26px;
    color: #0f0f0f;
    @media (max-width: 767px) {
      margin: 15px 0;
    }
    @media (max-width: 578px) {
      font-size: 28px;
      line-height: 120%;
    }
  }
  .formGroup {
    position: relative;
    max-width: 560px;
    margin: 0 auto;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  .p-button {
    width: 100%;
    margin-top: 26px;
    max-width: 560px;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .list {
    background: #fff;
    border: none;
    border-radius: 0 0 6px 6px;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    max-width: 523px;
    overflow-y: auto;
    padding: 0 !important;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 11;
    padding-left: 15px !important;
    margin-top: -4px;
  }
  .listData {
    border: 1px solid transparent;
    cursor: pointer;
    padding: 4px 0 4px 25px !important;
    text-align: left;
    color: #7d7d7d;
  }
  .locationIcon {
    right: 20px;
    top: 57px;
    height: 25px;
    position: absolute;
    transform: translateY(-50%);
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .mapMarker {
    position: absolute;
    transform: translate(-23px, 0px);
  }
  .p-inputtext {
    padding: 0px 20px !important;
  }
  .downArrow {
    background: url(../../assets/svg/caret-down.svg) no-repeat 0 center;
    width: 12px;
    height: 30px;
    display: inline-block;
    margin-left: 3px;
    border: none;
    transform: rotate(180deg);
    position: absolute;
    margin-top: -3px;
  }
}
