@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Heebo', sans-serif;
  background: #deddde;
}

/* Variables */
$font: 'Heebo', sans-serif;
$primary-color: #1c1f25;
$primary-hover-color: #23272e;
$secondary-color: #4f97ec;
$secondary-hover-color: #e89c31;
$white-color: #ffffff;
$white-grey-color: #f6f5f8;
$dark-grey-color: #0f0f0f;
$dark-black-color: #23272e;
$grey-color: #868c94;
$light-grey-color: #eeedee;
$white-green-color: #b3e8d8;
$over-due-color: #f82649;
$pending-color: #fb6b25;
$process-color: #f7b731;

/* Grid Set */
$prefix: '' !default;
$separator: '\\:' !default;

$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$gutter: 1rem !default;

$fieldMargin: 1rem !default;
$fieldLabelMargin: 0.5rem !default;
$helperTextMargin: 0.25rem !default;

$spacer: 1rem !default;

$breakpoints: (
  'sm': $sm,
  'md': $md,
  'lg': $lg,
  'xl': $xl
) !default;

.#{$prefix}grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1 * $gutter;
  margin-left: -1 * $gutter;
  margin-top: 0 * $gutter;
}

.#{$prefix}grid > #{$prefix}.col,
.#{$prefix}grid > [class*='#{$prefix}col'] {
  box-sizing: border-box;
}

.#{$prefix}grid-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.#{$prefix}grid-nogutter > .#{$prefix}col,
.#{$prefix}grid-nogutter > [class*='#{$prefix}col-'] {
  padding: 0;
}

.#{$prefix}col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 $gutter;
}

.#{$prefix}col-fixed {
  flex: 0 0 auto;
  padding: 0 $gutter;
}

$grid-columns: (
  'col-1': 8.3333%,
  'col-2': 16.6667%,
  'col-3': 25%,
  'col-4': 33.3333%,
  'col-5': 41.6667%,
  'col-6': 50%,
  'col-7': 58.3333%,
  'col-8': 66.6667%,
  'col-9': 75%,
  'col-10': 83.3333%,
  'col-11': 91.6667%,
  'col-12': 100%
);

$grid-column-offsets: (
  'col-offset-0': 0,
  'col-offset-1': 8.3333%,
  'col-offset-2': 16.6667%,
  'col-offset-3': 25%,
  'col-offset-4': 33.3333%,
  'col-offset-5': 41.6667%,
  'col-offset-6': 50%,
  'col-offset-7': 58.3333%,
  'col-offset-8': 66.6667%,
  'col-offset-9': 75%,
  'col-offset-10': 83.3333%,
  'col-offset-11': 91.6667%,
  'col-offset-12': 100%
);

@each $col, $width in $grid-columns {
  .#{$prefix}#{$col} {
    flex: 0 0 auto;
    padding: 0 $gutter;
    width: #{$width};
  }
}

@each $key, $val in $breakpoints {
  @media screen and (min-width: #{$val}) {
    .#{$key + $separator} {
      &#{$prefix}col {
        flex-grow: 1;
        flex-basis: 0;
        padding: 0 $gutter;
      }

      &#{$prefix}col-fixed {
        flex: 0 0 auto;
        padding: 0 $gutter;
      }

      @each $col, $width in $grid-columns {
        &#{$prefix}#{$col} {
          flex: 0 0 auto;
          padding: 0 $gutter;
          width: #{$width};
        }
      }
    }
  }
}
.wrap {
  padding: 0 $gutter;
}

/* Common CSS */
.btnsWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backBtn {
  font-size: 16px;
  line-height: 22px;
  color: $grey-color;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  svg {
    margin-right: 10px;
    vertical-align: middle;
  }
  // &:before {
  //   content: '\e900';
  //   font-family: primeicons;
  //   margin-right: 15px;
  // }
  &:hover {
    color: $dark-black-color;
    svg {
      path {
        stroke: $dark-black-color;
      }
    }
  }
}
.sectionGrid {
  padding: 0 70px;
  @media (max-width: 1199px) {
    padding: 0 50px;
  }
  @media (max-width: 991px) {
    padding: 0 40px;
  }
}
.greyBg {
  background: #deddde;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

// .grid {
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -0.5rem;
//   margin-left: -0.5rem;
//   margin-top: -0.5rem;
// }

// .grid > .col,
// .grid > [class*=col] {
//   box-sizing: border-box;
// }

// .grid-nogutter {
//   margin-right: 0;
//   margin-left: 0;
//   margin-top: 0;
// }

// .grid-nogutter > .col,
// .grid-nogutter > [class*=col-] {
//   padding: 0;
// }

// .col {
//   flex-grow: 1;
//   flex-basis: 0;
//   padding: 0.5rem;
// }

// .col-fixed {
//   flex: 0 0 auto;
//   padding: 0.5rem;
// }

// .col-1 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 8.3333%;
// }

// .col-2 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 16.6667%;
// }

// .col-3 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 25%;
// }

// .col-4 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 33.3333%;
// }

// .col-5 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 41.6667%;
// }

// .col-6 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 50%;
// }

// .col-7 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 58.3333%;
// }

// .col-8 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 66.6667%;
// }

// .col-9 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 75%;
// }

// .col-10 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 83.3333%;
// }

// .col-11 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 91.6667%;
// }

// .col-12 {
//   flex: 0 0 auto;
//   padding: 0.5rem;
//   width: 100%;
// }

// @media screen and (min-width: 576px) {
//   .sm\:col {
//     flex-grow: 1;
//     flex-basis: 0;
//     padding: 0.5rem;
//   }
//   .sm\:col-fixed {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//   }
//   .sm\:col-1 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 8.3333%;
//   }
//   .sm\:col-2 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 16.6667%;
//   }
//   .sm\:col-3 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 25%;
//   }
//   .sm\:col-4 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 33.3333%;
//   }
//   .sm\:col-5 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 41.6667%;
//   }
//   .sm\:col-6 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 50%;
//   }
//   .sm\:col-7 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 58.3333%;
//   }
//   .sm\:col-8 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 66.6667%;
//   }
//   .sm\:col-9 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 75%;
//   }
//   .sm\:col-10 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 83.3333%;
//   }
//   .sm\:col-11 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 91.6667%;
//   }
//   .sm\:col-12 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 100%;
//   }
// }
// @media screen and (min-width: 768px) {
//   .md\:col {
//     flex-grow: 1;
//     flex-basis: 0;
//     padding: 0.5rem;
//   }
//   .md\:col-fixed {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//   }
//   .md\:col-1 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 8.3333%;
//   }
//   .md\:col-2 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 16.6667%;
//   }
//   .md\:col-3 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 25%;
//   }
//   .md\:col-4 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 33.3333%;
//   }
//   .md\:col-5 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 41.6667%;
//   }
//   .md\:col-6 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 50%;
//   }
//   .md\:col-7 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 58.3333%;
//   }
//   .md\:col-8 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 66.6667%;
//   }
//   .md\:col-9 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 75%;
//   }
//   .md\:col-10 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 83.3333%;
//   }
//   .md\:col-11 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 91.6667%;
//   }
//   .md\:col-12 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 100%;
//   }
// }
// @media screen and (min-width: 992px) {
//   .lg\:col {
//     flex-grow: 1;
//     flex-basis: 0;
//     padding: 0.5rem;
//   }
//   .lg\:col-fixed {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//   }
//   .lg\:col-1 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 8.3333%;
//   }
//   .lg\:col-2 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 16.6667%;
//   }
//   .lg\:col-3 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 25%;
//   }
//   .lg\:col-4 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 33.3333%;
//   }
//   .lg\:col-5 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 41.6667%;
//   }
//   .lg\:col-6 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 50%;
//   }
//   .lg\:col-7 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 58.3333%;
//   }
//   .lg\:col-8 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 66.6667%;
//   }
//   .lg\:col-9 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 75%;
//   }
//   .lg\:col-10 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 83.3333%;
//   }
//   .lg\:col-11 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 91.6667%;
//   }
//   .lg\:col-12 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 100%;
//   }
// }
// @media screen and (min-width: 1200px) {
//   .xl\:col {
//     flex-grow: 1;
//     flex-basis: 0;
//     padding: 0.5rem;
//   }
//   .xl\:col-fixed {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//   }
//   .xl\:col-1 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 8.3333%;
//   }
//   .xl\:col-2 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 16.6667%;
//   }
//   .xl\:col-3 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 25%;
//   }
//   .xl\:col-4 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 33.3333%;
//   }
//   .xl\:col-5 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 41.6667%;
//   }
//   .xl\:col-6 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 50%;
//   }
//   .xl\:col-7 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 58.3333%;
//   }
//   .xl\:col-8 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 66.6667%;
//   }
//   .xl\:col-9 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 75%;
//   }
//   .xl\:col-10 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 83.3333%;
//   }
//   .xl\:col-11 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 91.6667%;
//   }
//   .xl\:col-12 {
//     flex: 0 0 auto;
//     padding: 0.5rem;
//     width: 100%;
//   }
// }
