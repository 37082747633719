@import '../../index';
.inputBox {
  position: relative;
}
.showError {
  position: absolute;
}
.showError-otp {
  width: 100%;
  text-align: start;
  bottom: -26px;
}
.errorMessage {
  font-size: 14px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #f43b56;
}
.otptext {
  margin-right: 30px;
  @media (max-width: 578px) {
    margin-right: 15px;
  }

  input {
    max-width: 65px !important;
    width: 100% !important;
    height: 70px;
    border: 1px solid transparent;
    border-radius: 6px;
    font-size: 38px;
    line-height: 53px;
    font-weight: 500;
    color: $grey-color;
    outline: none;
    &:hover {
      border: 1px solid $secondary-color;
    }
    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 0.2rem #c7d2fe;
    }
    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 30px;
      height: 55px;
    }
    @media (max-width: 578px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  &:last-child {
    margin-right: 0px;
  }
}
label {
  display: block;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  color: $dark-black-color;
  margin-bottom: 5px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
}
// .p-inputnumber,
.p-inputtext {
  font-size: 16px;
  line-height: 22px;
  color: $primary-color;
  height: 54px;
  border-radius: 6px;
  font-family: $font;
  font-weight: 400;
  width: 100%;
  border: 1px solid transparent;
  &:enabled:hover,
  &:enabled:focus {
    border-color: #4f97ec;
  }
  &::-webkit-input-placeholder {
    color: $grey-color;
  }
  &::-moz-placeholder {
    color: $grey-color;
  }
  &:-ms-input-placeholder {
    color: $grey-color;
  }
  &:-moz-placeholder {
    color: $grey-color;
  }
}
.p-password {
  width: 100%;
  .pi {
    cursor: pointer;
  }
}
.p-calendar {
  .p-button,
  .p-button:enabled:hover {
    background: transparent;
    border: transparent;
    color: $grey-color;
    position: absolute;
    right: 0;
  }
  .p-inputtext {
    border-radius: 6px;
  }
}
.field-checkbox > label,
.field-radiobutton > label {
  margin-bottom: 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  color: $dark-grey-color;
}
.p-radiobutton {
  .p-radiobutton-box.p-highlight,
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: $primary-color;
    background: $primary-color;
  }
  .p-radiobutton-box:not(.p-disabled).p-focus,
  .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    box-shadow: none;
    border-color: $primary-color;
  }
}
