@import '../../index';
.filterSection {
  margin-top: 40px;
}
.paginationSection {
  margin-top: 40px;
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1199px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 991px) {
    padding-left: 40px;
    padding-right: 40px;
    flex-wrap: wrap;
  }
  .totalResult {
    font-weight: 600;
    @media (max-width: 1199px) {
      width: 26%;
    }
    @media (max-width: 991px) {
      width: 50%;
    }
  }
  .btnSet {
    display: flex;
    align-items: center;
    @media (max-width: 1199px) {
      width: 34%;
    }
    @media (max-width: 991px) {
      width: 50%;
      margin: 0 auto;
    }
    .p-button.p-button-outlined {
      border-color: #1c1f25;
      height: 28px;
      border-radius: 20px;
      color: #1c1f25;
      padding: 5px 20px;
      background: transparent;
      span {
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;
      }
      &:enabled:hover {
        border-color: #23272e;
        background: transparent;
        color: #23272e;
      }
    }
    .p-button {
      background: #23272e;
      border-color: #23272e;
      height: 46px;
      border-radius: 30px;
      margin-left: 20px;
      margin-top: 0;
      &:first-child {
        margin-left: 0;
      }
      span {
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
      }
      &:enabled:hover {
        border-color: #1c1f25;
        background: #1c1f25;
        color: #fff;
      }
      svg {
        margin-left: 15px;
      }
    }
  }
}
