@import '../../index';
.ForgetBox {
  max-width: 500px;
  margin: 0 auto;
  @media (max-width: 767px) {
    margin-top: 15px;
    max-width: 700px;
  }
  h1 {
    font-size: 36px;
    line-height: 43px;
    margin-top: 0;
    font-weight: 500;
    margin-bottom: 26px;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }

  .p-button {
    width: 100%;
    margin-top: 26px;
  }

  .HS-forgetText {
    margin-top: 2.2rem;
  }
  .HSInput {
    margin-top: 26px;
  }
}
