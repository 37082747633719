@import '../../index';
.LoginBox {
  max-width: 500px;
  margin: 0 auto;
  @media (max-width: 767px) {
    margin-top: 15px;
    max-width: 700px;
  }
  h1 {
    font-size: 36px;
    line-height: 43px;
    margin-top: 0;
    font-weight: 500;
    margin-bottom: 26px;
    color: $dark-black-color;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }
  .field-checkbox {
    margin-bottom: 0;
    .p-checkbox-icon {
      font-size: 11px;
      font-weight: 700;
    }
  }
  .forgetText {
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: $dark-black-color;
    border-bottom: 1px solid transparent;
    &:hover,
    &:focus {
      border-bottom: 1px solid $dark-black-color;
    }
  }
  .p-button {
    width: 100%;
  }
  .Text {
    .linkText {
      font-size: 18px;
      line-height: 25px;
      color: $secondary-color;
      margin-left: 5px;
      text-decoration: none;
      &:hover,
      &:focus {
        border-bottom: 1px solid $secondary-color;
      }
    }
  }
  .HS-forgetText {
    margin-top: 1.625rem;
    margin-bottom: 1.625rem;
  }
  .HSInput {
    margin-top: 1.625rem;
  }
  .p-checkbox {
    .p-checkbox-box {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      &.p-highlight {
        border-color: $primary-color;
        background: $primary-color;
      }
      font-size: 12px;
    }
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: $primary-color;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: $primary-color;
    background: $primary-color;
  }
}
