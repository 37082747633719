@import '../../index';
.cardBox {
  margin-top: auto;
  @media (max-width: 767px) {
    margin-top: 50px;
  }
}
.p-card.p-component {
  font-family: $font;
  color: $light-grey-color;
  box-shadow: none;
  background: $primary-color;
  padding: 30px;
  @media (max-width: 767px) {
    padding: 20px;
  }
  .p-card-body {
    padding: 0;
    .p-card-content {
      padding: 0;
      font-size: 16px;
      line-height: 22px;
      .customerInfo {
        padding-top: 24px;
        display: flex;
        align-items: center;
        .customerImg {
          margin-right: 10px;
          img {
            width: 37px;
            height: 37px;
            object-fit: cover;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #4f97ec;
          }
        }
        .name {
          padding-bottom: 3px;
        }
        .designation {
          font-size: 12px;
          line-height: 14px;
          color: $grey-color;
        }
      }
    }
  }
}
