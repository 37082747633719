.pagination {
  @media (max-width: 1199px) {
    width: 40%;
  }
  @media (max-width: 991px) {
    width: 50%;
  }
  .p-paginator-current {
    display: none;
  }
  .p-paginator {
    background: transparent;
    .p-paginator-pages .p-paginator-page {
      color: #1c1f25;
      border-radius: 2px;
      height: 2rem;
      font-weight: 600;
    }
    .p-paginator-page.p-highlight {
      height: 2rem;
      border-radius: 2px;
      background: #1c1f25;
      color: #fff;
    }
    .p-paginator-next,
    .p-paginator-prev {
      border-radius: 2px;
      height: 2rem;
      font-weight: 600;
    }
  }
}
