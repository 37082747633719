@import '../../index';
.breadcrumbs {
  padding: 20px 70px;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 20px 0 0;
  @media (max-width: 1199px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 991px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  li {
    list-style: none;
    color: #868c94;
    padding: 0 20px;
    &:first-child {
      padding-left: 0;
    }
    a {
      color: #1c1f25;
      text-decoration: none;
    }
  }
}
