@import '../../../index';
.buttonBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    margin-top: 20px;
    width: 100%;
  }
  .p-button-outlined {
    border-color: #fff;
    margin-top: 0;
    width: 185px;
    &:enabled:hover {
      background: #fff;
      border-color: #fff;
      color: #1c1f25;
      span {
        color: #1c1f25;
      }
      svg {
        path {
          stroke: #1c1f25;
        }
      }
    }
    &:last-child {
      margin-top: 20px;
      @media (max-width: 991px) {
        margin-left: 20px;
        margin-top: 0;
      }
    }
    span {
      color: #fff;
      font-size: 18px;
      line-height: 25px;
    }
    svg {
      order: -1;
      margin-right: 10px;
    }
  }
}
