@import '../../index';
.companyInfo {
  background: linear-gradient(221.31deg, #01d0f2 4.91%, #0049fd 101.19%);
  padding: 45px;
  border-radius: 6px;
  max-width: 455px;
  min-height: calc(100vh - 2rem);
  display: flex;
  @media (max-width: 991px) {
    padding: 30px;
  }
  @media (max-width: 767px) {
    margin: 0 auto;
    padding: 20px;
    min-height: auto;
    max-width: 700px;
  }
  .boxWrap {
    display: flex;
    flex-direction: column;
    .titleWrap {
      margin-top: auto;
      @media (max-width: 767px) {
        margin-top: 30px;
      }
      .title {
        font-size: 40px;
        line-height: 48px;
        font-weight: 500;
        color: $white-color;
        margin-top: auto;
        @media (max-width: 991px) {
          font-size: 36px;
          line-height: 40px;
        }
        @media (max-width: 767px) {
          font-size: 30px;
          line-height: 36px;
        }
      }
      .text {
        font-size: 24px;
        line-height: 28px;
        color: $white-color;
        margin-top: 48px;
        @media (max-width: 991px) {
          font-size: 20px;
          line-height: 24px;
        }
        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }
    }
  }
}
