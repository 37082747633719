@import '../../index';
.propertyView {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  .propertyList {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    width: calc(100% - 270px);
    @media (max-width: 1199px) {
      width: calc(100% - 250px);
    }
    @media (max-width: 991px) {
      width: 100%;
    }
    position: relative;
    .imgBox {
      img {
        width: 140px;
        height: 140px;
        border-radius: 10px;
        vertical-align: middle;
      }
    }
  }
  .btnSection {
    width: 290px;
    background: linear-gradient(221.31deg, #01d0f2 4.91%, #0049fd 101.19%);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    @media (max-width: 1199px) {
      width: 250px;
    }
    @media (max-width: 991px) {
      width: 100%;
      margin-left: 0;
      margin-top: -20px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 20px;
    }
  }
}
