@import '../../../index';
.statusView {
  padding-left: 40px;
  width: 40%;
  @media (max-width: 1199px) {
    padding-left: 20px;
    width: 42%;
  }
  .status {
    position: relative;
    &:before {
      content: '';
      width: 2px;
      height: calc(100% + 55px);
      background: #eeedee;
      position: absolute;
      left: 0;
      top: -28px;
    }
    li {
      padding-bottom: 30px;
      &:last-child {
        padding-bottom: 0;
      }
      .iconBox {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #deddde;
        display: inline-block;
        margin-right: 18px;
        vertical-align: middle;
        margin-left: -9px;
        position: relative;
      }
      .text {
        font-size: 14px;
        line-height: 20px;
        color: #868c94;
      }
      &.active {
        .text {
          font-size: 18px;
          line-height: 25px;
          color: #1c1f25;
          @media (max-width: 1024px) {
            font-size: 16px;
            line-height: 23px;
          }
        }
        .iconBox {
          width: 26px;
          height: 26px;
          background: linear-gradient(134.21deg, #01ecfb 1.34%, #8310f5 99.49%);
          position: relative;
          margin-left: -12px;
          &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 9px;
            height: 9px;
            background: #fff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
