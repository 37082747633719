@import '../../index';
.inviteBlock {
  .inviteBox {
    max-width: 800px;
    margin: 0;
    display: block;
    flex-direction: column;
    min-height: auto;
    @media (max-width: 767px) {
      min-height: auto;
    }
    h1 {
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: $dark-grey-color;
      margin: 0 0 26px;
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
      }
    }
    .memberBox {
      margin-top: 0;
      // width: calc(100% - 50px);
      @media (max-width: 767px) {
        margin-top: 30px;
        // width: calc(100% - 30px);
      }
      .labelWrap {
        display: flex;
        label {
          &:first-child {
            width: 40%;
            text-align: left;
            position: relative;
          }
          width: 20%;
          position: relative;
          @media (max-width: 1199px) {
            width: 25%;
          }
          &:last-child {
            width: 25%;
            @media (max-width: 1199px) {
              width: 20%;
            }
          }
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
      .userWrap {
        max-height: 550px;
        overflow-y: auto;
        overflow-x: hidden;
        @media (max-width: 767px) {
          max-height: 100%;
        }
        .inviteUser {
          position: relative;
          display: flex;
          background: #fff;
          border-radius: 6px;
          margin-bottom: 6px;
          overflow: hidden;
          .inputWrap {
            display: flex;
            border-radius: 10px;
            width: 100%;
            .inputBx {
              width: 40%;
              padding: 10px 15px;
              label {
                margin: 0;
              }
              input {
                border: none;
                height: 35px;
                padding: 0;
                color: $grey-color;
              }
            }
            .dropDownWrap {
              width: 20%;
              @media (max-width: 1199px) {
                width: 25%;
              }
              .dropdownBox {
                padding: 10px 0;
                label {
                  margin: 0;
                }
                .p-dropdown {
                  border: none;
                  border-radius: 0;
                  height: 35px;
                  width: calc(100% - 25px);
                  @media (max-width: 1199px) {
                    width: calc(100% - 15px);
                  }
                  .p-dropdown-label {
                    padding: 0;
                    height: 100%;
                    line-height: 35px;
                    color: $grey-color;
                  }
                  .p-dropdown-trigger {
                    width: auto;
                    padding-left: 5px;
                    .pi {
                      @media (max-width: 767px) {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
            .wrapBtns {
              width: 25%;
              @media (max-width: 1199px) {
                width: 20%;
              }
            }
          }
          .errorMessage {
            position: absolute;
            // bottom: -6px;
            top: 100%;
            margin: 5px 0 0;
          }
          &:hover {
            .delUser {
              background: $secondary-color;
              width: 65px;
              margin-left: auto;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              cursor: pointer;
              padding: 10px 20px 10px 0;
              height: 55px;
              svg {
                path {
                  stroke: #fff;
                }
              }
              &::before {
                content: '';
                background: #fff;
                border-radius: 6px;
                display: inline-block;
                width: 55px;
                height: 55px;
                position: absolute;
                right: 60px;
              }
            }
          }
        }
      }
      // .addUser {
      //   font-size: 14px;
      //   line-height: 20px;
      //   color: $primary-color;
      //   cursor: pointer;
      //   padding: 0 0 0 15px;
      //   display: inline-block;
      //   &:hover {
      //     color: $primary-hover-color;
      //   }
      // }
      // .delUser {
      //   position: relative;
      //   // right: -30px;
      //   // top: 15px;
      //   width: 54px;
      //   height: 54px;
      //   border: 1px solid #f00;
      //   border-radius: 10px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   margin-left: 10px;
      //   cursor: pointer;
      //   svg {
      //     width: 22px;
      //     height: 22px;
      //   }
      // }
    }
  }
}
