@import '../../index';

.p-button {
  background: $primary-color;
  font-family: $font;
  border-radius: 6px;
  border-color: $primary-color;
  height: 54px;
  color: $white-color;
  .p-button-label {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &:enabled:hover {
    background: $primary-hover-color;
    border-color: $primary-hover-color;
  }
  &.p-button-secondary {
    background: $secondary-color;
    border-color: $secondary-color;
    color: $dark-grey-color;
    &:enabled:hover {
      background: $secondary-hover-color;
      border-color: $secondary-hover-color;
      color: $dark-grey-color;
    }
  }
  &.p-button-noBg {
    background: none;
    border-color: transparent;
    color: $primary-color;
    &:enabled:hover {
      background: none;
      border-color: $primary-color;
      .p-button-label {
        color: $primary-color;
      }
    }
  }
}
