@import '../../index';
._pointer {
  cursor: pointer;
}
.verifyEmail {
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 15px;
  }
  .headingText {
    font-size: 36px;
    display: block;
    line-height: 43px;
    margin-top: 0;
    font-weight: 500;
    color: $dark-black-color;

    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media (max-width: 578px) {
      font-size: 23px;
      line-height: 30px;
    }
  }

  .verifyEmailPara {
    margin: 6px 0 0;
    font-size: 18px;
    line-height: 25px;
  }
  .forgetText {
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: $dark-grey-color;
    border-bottom: 1px solid transparent;
    &:hover,
    &:focus {
      border-bottom: 1px solid $dark-grey-color;
    }
  }
  .p-button {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 26px;
  }
  .confirmText {
    color: $grey-color;
    margin: 0;
    font-size: 18px;
    line-height: 25px;
  }
  .Text {
    .linkText {
      font-size: 18px;
      line-height: 25px;
      color: $primary-color;
      margin-left: 5px;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      &:hover,
      &:focus {
        border-bottom: 1px solid $primary-color;
      }
    }
  }
  .disableLink {
    color: #ababab;
  }
  .enableLink {
    color: $secondary-color;
  }
  .verifytext {
    line-height: 16px;
  }
  .inputOtpBox {
    margin-top: 26px;
  }
}
