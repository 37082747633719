@import '../../index';
label {
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: $dark-black-color;
  margin-bottom: 6px;
}
.p-dropdown {
  font-size: 16px;
  line-height: 30px;
  color: $dark-grey-color;
  height: 54px;
  border-radius: 6px;
  font-family: $font;
  font-weight: 400;
  border: 1px solid transparent;
  .p-dropdown-label {
    color: $grey-color;
    font-family: $font;
    line-height: 30px;
    &.p-placeholder {
      color: $grey-color;
    }
  }
  &:not(.p-disabled):hover {
    border-color: $secondary-color;
  }
  &::-webkit-input-placeholder {
    color: $grey-color;
  }
  &::-moz-placeholder {
    color: $grey-color;
  }
  &:-ms-input-placeholder {
    color: $grey-color;
  }
  &:-moz-placeholder {
    color: $grey-color;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $primary-color;
  background: rgba($primary-color, 0.2);
}
/* ================ Multiselect CSS Start ================ */
.p-multiselect {
  font-family: $font;
  font-size: 16px;
  height: 54px;
  line-height: 30px;
}
.p-multiselect:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus {
  border-color: $primary-color;
  box-shadow: none;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: $primary-color;
  background: rgba($primary-color, 0.1);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}
/* ================ Multiselect CSS End ================ */

/* ================ Checkbox CSS Start ================ */
.p-checkbox {
  .p-checkbox-box {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    &.p-highlight {
      border-color: $primary-color;
      background: $primary-color;
    }
    font-size: 12px;
  }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: $primary-color;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $primary-color;
  background: $primary-color;
}
/* ================ Checkbox CSS End ================ */
