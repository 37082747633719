@import '../../index';
.field-checkbox > label,
.field-radiobutton > label {
  margin-bottom: 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  color: $dark-black-color;
}
.p-checkbox {
  .p-checkbox-box {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid transparent;
    &.p-highlight {
      border-color: $primary-color;
      background: $primary-color;
    }
    font-size: 12px;
  }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: $primary-color;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $primary-color;
  background: $primary-color;
}
